import {
  AlliancesSearchFilterDTO,
  AvatarNames,
  DeleteUsersDTO,
  UpdateUserDTO,
  UserPrivateDTO
} from '@cityinvaders/dtos'
import { Button, Card, Checkbox, Col, Icon, Modal, Row, Select } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React, { FC, FormEvent, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchAlliances } from '../../redux/alliance'
import { deleteUsers, updateUser } from '../../redux/user'
import styles from './UserCard.module.scss'

interface IOwnProps {
  user: UserPrivateDTO | undefined
  isEditable?: boolean
  loading: boolean
}

const mapStateToProps = (state: IRootState) => ({
  alliances: state.allianceState.alliances
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  updateUser: (user: UpdateUserDTO) => dispatch(updateUser(user)),
  fetchAlliances: (params: AlliancesSearchFilterDTO) => dispatch(fetchAlliances(params)),
  deleteUsers: (params: DeleteUsersDTO) => dispatch(deleteUsers(params))
})

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FormComponentProps &
  IOwnProps

const UserForm: FC<IProps> = ({
  user,
  isEditable = false,
  alliances,
  loading,
  form,
  updateUser: updateUserStore,
  fetchAlliances: fetchAlliancesStore,
  deleteUsers: deleteUsersStore
}) => {
  const { getFieldDecorator, setFieldsValue, validateFieldsAndScroll } = form

  useEffect(() => {
    fetchAlliancesStore({ lightDTO: true })
  }, [fetchAlliancesStore])

  // Init the form fields
  useEffect(() => {
    if (user) {
      setFieldsValue({
        email: user.email,
        username: user.username,
        notificationsNewsPush: user.notificationsNewsPush,
        notificationsPlacePush: user.notificationsPlacePush,
        notificationsBattlePush: user.notificationsBattlePush,
        victoriesCount: user.victoriesCount,
        defeatsCount: user.defeatsCount,
        avatarId: user.avatarId,
        allianceId: user.allianceId,
        allianceRole: user.allianceRole
      })
    }
  }, [user, setFieldsValue])

  const editUser = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    validateFieldsAndScroll(async (errors, fieldsValue: UpdateUserDTO) => {
      if (errors || !user) {
        return
      }

      try {
        await updateUserStore({ ...fieldsValue, id: user.id })
      } catch (e) {
        console.log(e)
        message.error('An error occured when trying to update the user.')
      }

      message.success('The user was successfully updated.')
    })
  }

  const handleDelete = () => {
    if (!user) {
      return
    }

    Modal.confirm({
      title: 'Do you really want to delete this user ?',
      onOk: () => deleteUsersStore({ ids: [user.id], hardDelete: false })
    })
  }

  return (
    <Card
      type="inner"
      className={styles.card}
      title={user && user.username}
      extra={
        <Icon
          type="delete"
          theme="twoTone"
          className={styles.deleteButton}
          twoToneColor="#ff4d4f"
          onClick={handleDelete}
        />
      }
    >
      <Form onSubmit={editUser} className={styles.form}>
        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            rules: [{ required: true, type: 'email' }]
          })(<Input type="email" disabled={!isEditable} />)}
        </Form.Item>
        <Form.Item label="Username">
          {getFieldDecorator('username', {
            rules: [{ required: true, min: 2, max: 20 }]
          })(<Input disabled={!isEditable} />)}
        </Form.Item>
        <Row gutter={16} className={styles.marginBottom0}>
          <Col span={12}>
            <Form.Item label="Victories count">
              {getFieldDecorator('victoriesCount', {
                rules: [
                  { required: true, min: 0, type: 'number', transform: value => Number(value) }
                ]
              })(<Input type="number" disabled={!isEditable} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Defeats count">
              {getFieldDecorator('defeatsCount', {
                rules: [
                  { required: true, min: 0, type: 'number', transform: value => Number(value) }
                ]
              })(<Input type="number" disabled={!isEditable} />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Avatar">
          {getFieldDecorator('avatarId', {
            rules: [{ required: true }]
          })(
            <Select disabled={!isEditable}>
              {Object.keys(AvatarNames).map((avatar: any) => (
                <Select.Option key={avatar} value={AvatarNames[avatar]}>
                  {AvatarNames[avatar]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {/*        <Form.Item label="Alliance">
          {getFieldDecorator('allianceId')(
            <Select allowClear disabled={!isEditable}>
              {alliances.map(alliance => (
                <Select.Option key={alliance.id} value={alliance.id}>
                  {alliance.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Alliance Role">
          {getFieldDecorator('allianceRole')(
            <Select allowClear disabled={!isEditable}>
              {Object.keys(AllianceRoles).map((alliance: any) => (
                <Select.Option key={alliance} value={AllianceRoles[alliance]}>
                  {AllianceRoles[alliance]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>*/}
        <label>Notifications :</label>
        <Row>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('notificationsNewsPush', {
                initialValue: false,
                valuePropName: 'checked'
              })(<Checkbox disabled={!isEditable}>News push</Checkbox>)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('notificationsPlacePush', {
                initialValue: false,
                valuePropName: 'checked'
              })(<Checkbox disabled={!isEditable}>Place push</Checkbox>)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('notificationsBattlePush', {
                initialValue: false,
                valuePropName: 'checked'
              })(<Checkbox disabled={!isEditable}>Battle push</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
        {isEditable && (
          <Button type="primary" htmlType="submit" className={styles.saveButton} loading={loading}>
            Save
          </Button>
        )}
      </Form>
    </Card>
  )
}

const UserCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create<IProps>({ name: 'user_form' })(UserForm)
)

export default UserCard
