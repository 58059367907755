import { createReducer } from '../utils'
import { addBattle, removeBattles, setBattles, setLoading } from './actions'
import { ActionsTypes, IBattleState } from './model'

const initialState: IBattleState = {
  battles: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_BATTLES]: (
    state: IBattleState,
    { battles }: ReturnType<typeof setBattles>
  ): IBattleState => ({
    ...state,
    battles: battles.data,
    total: battles.total
  }),
  [ActionsTypes.ADD_BATTLE]: (
    state: IBattleState,
    { battle }: ReturnType<typeof addBattle>
  ): IBattleState => ({
    ...state,
    battles: [...state.battles, battle]
  }),
  [ActionsTypes.REMOVE_BATTLES]: (
    state: IBattleState,
    { ids }: ReturnType<typeof removeBattles>
  ): IBattleState => ({
    ...state,
    battles: state.battles.filter(b => !ids.includes(b.id)),
    total: state.total - ids.length
  }),
  [ActionsTypes.SET_BATTLE_LOADING]: (
    state: IBattleState,
    { loading }: ReturnType<typeof setLoading>
  ): IBattleState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
