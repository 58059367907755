import React from 'react'
import UsersTable from '../../components/UsersTable/UsersTable'
import styles from './UsersPage.module.scss'

const UsersPage = () => (
  <div className={styles.container}>
    <h1>Users</h1>
    <UsersTable />
  </div>
)

export default UsersPage
