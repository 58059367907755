import {
  CharacterDTO,
  CharacterTypes,
  CharacterVariants,
  CreateCharacterDTO,
  ItemTemplateSearchFilterDTO,
  UpdateCharacterDTO
} from '@cityinvaders/dtos'
import { Button, Select } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React, { FC, FormEvent, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchItemTemplates } from '../../redux/itemTemplate'
import { createUserCharacter, updateUserCharacter } from '../../redux/user'
import styles from './CharacterForm.module.scss'

interface IOwnProps {
  userId?: string
  isEditable?: boolean
  character?: CharacterDTO
  closeModal?: () => void
  loading: boolean
}

const mapStateToProps = (state: IRootState) => ({
  itemTemplates: state.itemTemplateState.itemTemplates
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createUserCharacter: (character: CreateCharacterDTO) => dispatch(createUserCharacter(character)),
  updateUserCharacter: (character: UpdateCharacterDTO) => dispatch(updateUserCharacter(character)),
  fetchItemTemplates: (params: ItemTemplateSearchFilterDTO) => dispatch(fetchItemTemplates(params))
})

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FormComponentProps &
  IOwnProps

const CharacterFormComponent: FC<IProps> = ({
  userId,
  isEditable = false,
  character,
  closeModal,
  loading,
  form,
  itemTemplates,
  createUserCharacter: createUserCharacterStore,
  updateUserCharacter: updateUserCharacterStore,
  fetchItemTemplates: fetchItemTemplatesStore
}) => {
  const { getFieldDecorator, setFieldsValue, validateFieldsAndScroll, resetFields } = form

  // Fetch the itemTemplates
  useEffect(() => {
    fetchItemTemplatesStore({})
  }, [fetchItemTemplatesStore])

  // Init the form fields
  useEffect(() => {
    if (character) {
      setFieldsValue({
        name: character.name,
        type: character.type,
        level: character.level,
        variant: character.variant,
        itemTemplates: character.items.map(item => item.reference.name),
        skills: character.skills
      })
    }
  }, [character, setFieldsValue])

  const addCharacter = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    validateFieldsAndScroll(async (errors, fieldsValue: CreateCharacterDTO) => {
      if (errors || !userId) {
        return
      }

      try {
        await createUserCharacterStore({ ...fieldsValue, userId })
        resetFields()
        message.success('The character was successfully added.')

        if (closeModal) {
          closeModal()
        }
      } catch (e) {
        console.log(e)
        message.error('An error occured when trying to create the character.')
      }
    })
  }

  const editCharacter = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    validateFieldsAndScroll(async (errors, fieldsValue: UpdateCharacterDTO) => {
      if (errors || !character) {
        return
      }

      try {
        await updateUserCharacterStore({ ...fieldsValue, id: character.id })
        message.success('The character was successfully updated.')
      } catch (e) {
        console.log(e)
        message.error('An error occured when trying to update the character.')
      }
    })
  }

  return (
    <Form onSubmit={character ? editCharacter : addCharacter} className={styles.form}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          rules: [{ required: true }]
        })(<Input disabled={!isEditable} />)}
      </Form.Item>
      <Form.Item label="Type">
        {getFieldDecorator('type', {
          rules: [{ required: true }]
        })(
          <Select disabled={!isEditable}>
            {Object.keys(CharacterTypes).map((characterType: any) => (
              <Select.Option key={characterType} value={CharacterTypes[characterType]}>
                {CharacterTypes[characterType]}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Level">
        {getFieldDecorator('level', {
          initialValue: 0,
          rules: [{ required: true, min: 0, type: 'number', transform: value => Number(value) }]
        })(<Input type="number" disabled={!isEditable} />)}
      </Form.Item>
      <Form.Item label="Variant">
        {getFieldDecorator('variant', {
          rules: [{ required: true }]
        })(
          <Select disabled={!isEditable}>
            {Object.keys(CharacterVariants)
              .filter((e: any) => !isNaN(Number(CharacterVariants[e])))
              .map((characterVariant: any) => (
                <Select.Option key={characterVariant} value={CharacterVariants[characterVariant]}>
                  {CharacterVariants[characterVariant]}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
      {/*      <Form.Item label="Skills">
        {getFieldDecorator('skills')(
          <Select disabled={!isEditable} mode="multiple">
            {Object.values(SkillsTypes).map(skill => (
              <Select.Option key={skill} value={skill}>
                {skill}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Items">
        {getFieldDecorator('itemTemplates')(
          <Select disabled={!isEditable} mode="multiple">
            {itemTemplates.map(itemTemplate => (
              <Select.Option key={itemTemplate.name} value={itemTemplate.name}>
                {itemTemplate.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>*/}
      {isEditable && (
        <Button type="primary" htmlType="submit" className={styles.saveButton} loading={loading}>
          {isEditable && character ? 'Save' : 'Create'}
        </Button>
      )}
    </Form>
  )
}

const CharacterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create<IProps>({ name: 'character_form' })(CharacterFormComponent)
)

export default CharacterForm
