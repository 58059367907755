import { DeleteUsersDTO, UserPrivateDTO, UsersSearchParamsDTO } from '@cityinvaders/dtos'
import Modal from 'antd/lib/modal'
import { PaginationConfig } from 'antd/lib/pagination'
import Table, { ColumnProps } from 'antd/lib/table'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { deleteUsers, fetchUsers } from '../../redux/user'
import styles from './UsersTable.module.scss'

const PAGE_SIZE = 30

const mapStateToProps = (state: IRootState) => ({
  users: state.userState.users,
  total: state.userState.total,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchUsers: (params: UsersSearchParamsDTO) => dispatch(fetchUsers(params)),
  deleteUsers: (params: DeleteUsersDTO) => dispatch(deleteUsers(params))
})

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const UsersTable: FC<IProps> = props => {
  const columns: Array<ColumnProps<UserPrivateDTO>> = [
    {
      title: 'E-mail',
      dataIndex: 'email',
      render: (name, record) => <Link to={'/users/' + record.id}>{name}</Link>
    },
    {
      title: 'Name',
      render: (name, record) =>
        `${record.firstname ? record.firstname : ''} ${record.lastname ? record.lastname : ''}`
    },
    {
      title: 'Username',
      dataIndex: 'username'
    },
    {
      title: 'Is Admin',
      render: (name, record) => (record.admin ? 'Yes' : 'No')
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div className={styles.action} onClick={() => handleDelete(record.id)}>
          Supprimer
        </div>
      )
    }
  ]

  const { fetchUsers: fetchUsersStore } = props
  useEffect(() => {
    fetchUsersStore({ filters: {}, paginationsOptions: { take: PAGE_SIZE } })
  }, [fetchUsersStore])

  const handleTableChange = (pagination: PaginationConfig) => {
    fetchUsersStore({
      filters: {},
      paginationsOptions: {
        skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
        take: PAGE_SIZE
      }
    })
  }

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Do you really want to delete this user ?',
      onOk: () => props.deleteUsers({ ids: [id], hardDelete: false })
    })
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.users}
        rowKey="id"
        pagination={{ total: props.total, defaultPageSize: PAGE_SIZE }}
        onChange={handleTableChange}
        loading={props.loading}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
