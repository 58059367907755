import { BattleDTO } from '@cityinvaders/dtos'

export enum ActionsTypes {
  SET_BATTLES = 'SET_BATTLES',
  ADD_BATTLE = 'ADD_BATTLE',
  REMOVE_BATTLES = 'REMOVE_BATTLES',
  SET_BATTLE_LOADING = 'SET_BATTLE_LOADING'
}

export interface IBattleState {
  battles: BattleDTO[]
  total: number
  loading: boolean
}
