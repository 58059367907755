import { CharacterDTO, DeleteCharacterDTO } from '@cityinvaders/dtos'
import { Card, Icon, Modal } from 'antd'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { deleteUserCharacter } from '../../redux/user'
import CharacterForm from '../CharacterForm/CharacterForm'
import styles from './CharacterCard.module.scss'

interface IOwnProps {
  character: CharacterDTO
  isEditable?: boolean
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  deleteUserCharacter: (params: DeleteCharacterDTO) => dispatch(deleteUserCharacter(params))
})

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IOwnProps

const CharacterCard: FC<IProps> = ({
  character,
  isEditable = false,
  loading,
  deleteUserCharacter: deleteUserCharacterStore
}) => {
  const handleDelete = () => {
    Modal.confirm({
      title: 'Do you really want to delete this character ?',
      onOk: () => deleteUserCharacterStore({ id: character.id })
    })
  }

  return (
    <Card
      type="inner"
      className={styles.card}
      title={character.name}
      extra={
        <Icon
          type="delete"
          theme="twoTone"
          className={styles.deleteButton}
          twoToneColor="#ff4d4f"
          onClick={handleDelete}
        />
      }
    >
      <CharacterForm character={character} loading={loading} isEditable={isEditable} />
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CharacterCard)
