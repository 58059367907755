import { Button, Icon, Modal } from 'antd'
import React, { useState } from 'react'
import ItemTemplateForm from '../../components/ItemTemplateForm/ItemTemplateForm'
import ItemTemplatesTable from '../../components/ItemTemplatesTable/ItemTemplatesTable'
import styles from './ItemTemplatesPage.module.scss'

const ItemTemplatesPage = () => {
  const [visible, setVisible] = useState(false)

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Item templates</h1>
        <Button type="primary" onClick={() => setVisible(true)}>
          <Icon type="plus" />
        </Button>
      </div>
      <ItemTemplatesTable />
      <Modal
        visible={visible}
        title="Create a new item template"
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <ItemTemplateForm closeModal={() => setVisible(false)} />
      </Modal>
    </div>
  )
}

export default ItemTemplatesPage
