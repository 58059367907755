import { MapDTO } from '@cityinvaders/dtos'

export enum ActionsTypes {
  SET_MAPS = 'SET_MAPS',
  SET_MAP_LOADING = 'SET_MAP_LOADING'
}

export interface IMapState {
  maps: MapDTO[]
  loading: boolean
}
