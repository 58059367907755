import { LoginResponseDTO } from '@cityinvaders/dtos'

export const setUserInLocalStorage = (auth: LoginResponseDTO) => {
  localStorage.setItem('sessionId', auth.sessionId)
  localStorage.setItem('userId', auth.user.id)
}

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('sessionId')
  localStorage.removeItem('userId')
}

export const getUserFromLocalStorage = () => {
  const sessionId = localStorage.getItem('sessionId')
  const userId = localStorage.getItem('userId')
  return { sessionId, userId }
}
