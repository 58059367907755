import { GetCharactersByUserIdDTO } from '@cityinvaders/dtos'
import { Button, Icon, Modal } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchUserCharacters } from '../../redux/user'
import CharacterCard from '../CharacterCard/CharacterCard'
import CharacterForm from '../CharacterForm/CharacterForm'
import styles from './UserCharacters.module.scss'

const mapStateToProps = (state: IRootState) => ({
  userCharacters: state.userState.userCharacters,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchUserCharacters: (params: GetCharactersByUserIdDTO) => dispatch(fetchUserCharacters(params))
})

interface IOwnProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IOwnProps

const UserCharacters: FC<IProps> = ({
  id,
  userCharacters,
  loading,
  fetchUserCharacters: fetchUserCharactersStore
}) => {
  const [visible, setVisible] = useState(false)

  // Fetch the user characters
  useEffect(() => {
    fetchUserCharactersStore({ userId: id })
  }, [id, fetchUserCharactersStore])

  return (
    <>
      <div className={styles.addZone}>
        <h1>Characters</h1>
        <Button type="primary" onClick={() => setVisible(true)}>
          <Icon type="plus" />
        </Button>
      </div>
      {!userCharacters.length && <p>No characters</p>}
      <div className={styles.characters}>
        {userCharacters.map(userCharacter => (
          <CharacterCard key={userCharacter.id} character={userCharacter} isEditable={true} />
        ))}
      </div>
      <Modal
        visible={visible}
        title="Create a new character"
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <CharacterForm
          userId={id}
          loading={loading}
          isEditable={true}
          closeModal={() => setVisible(false)}
        />
      </Modal>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCharacters)
