import { CreateBattleDTO, GoalsNames, Teams, UsersSearchParamsDTO } from '@cityinvaders/dtos'
import { Button, Cascader, Checkbox, Modal } from 'antd'
import { CascaderOptionType } from 'antd/lib/cascader'
import Form, { FormComponentProps } from 'antd/lib/form'
import message from 'antd/lib/message'
import React, { FC, FormEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { createBattle } from '../../redux/battle'
import { fetchMaps } from '../../redux/map'
import { fetchUsers } from '../../redux/user'
import styles from './BattleForm.module.scss'
import UserTeamCard from './UserTeamCard/UserTeamCard'
import UserTeamForm from './UserTeamForm/UserTeamForm'

const mapStateToProps = (state: IRootState) => ({
  maps: state.mapState.maps,
  loading: state.battleState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createBattle: (params: CreateBattleDTO) => dispatch(createBattle(params)),
  fetchUsers: (params: UsersSearchParamsDTO) => dispatch(fetchUsers(params)),
  fetchMaps: () => dispatch(fetchMaps())
})

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  FormComponentProps

const BattleFormComponent: FC<IProps> = ({
  loading,
  form,
  maps,
  createBattle: createBattleStore,
  fetchMaps: fetchMapsStore,
  fetchUsers: fetchUsersStore,
  history
}) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form
  const [visible, setVisible] = useState(false)
  const [usersTeams, setUsersTeams] = useState<Array<{ userId: string; team: Teams }>>([])
  const [mapsOption, setMapsOption] = useState<CascaderOptionType[]>()
  const [mapName, setMapName] = useState('')
  const [goalName, setGoalName] = useState('')

  useEffect(() => {
    fetchMapsStore()
  }, [fetchMapsStore])

  useEffect(() => {
    fetchUsersStore({ filters: {}, paginationsOptions: {} })
  }, [fetchUsersStore])

  useEffect(() => {
    setMapsOption(
      maps.map(map => ({
        value: map.name,
        label: map.name,
        children: map.goals.map(goal => ({
          value: goal.name,
          label: `${goal.name}`
        }))
      }))
    )
  }, [maps, setMapsOption])

  const addUserTeam = (userTeam: { userId: string; team: Teams }) => {
    setUsersTeams([...usersTeams, userTeam])
  }

  const addBattle = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    validateFieldsAndScroll(async (errors, values: CreateBattleDTO) => {
      if (errors) {
        return
      }

      try {
        await createBattleStore({ ...values, mapName, goalName: goalName as GoalsNames })
        message.success('The battle was successfully added.')
        history.push('/battles')
      } catch (e) {
        console.log(e)
        message.error('An error occured when trying to create the battle.')
      }
    })
  }

  const handleMapChange = (value: string[]) => {
    setMapName(value[0])
    setGoalName(value[1])
  }

  const removeUserTeam = (userId: string) => {
    setUsersTeams(usersTeams.filter(ut => ut.userId !== userId))
  }

  return (
    <>
      <Form onSubmit={addBattle} className={styles.form}>
        <label className={styles.label}>
          Users :{' '}
          <span className={styles.addUser} onClick={() => setVisible(true)}>
            add
          </span>
        </label>
        {
          <div className={styles.userTeamsContainer}>
            {usersTeams.map(userTeam => (
              <UserTeamCard
                key={userTeam.userId}
                userTeam={userTeam}
                removeUserTeam={removeUserTeam}
              />
            ))}
          </div>
        }
        <Form.Item label="Map">
          {getFieldDecorator('mapName', {
            rules: [{ required: true }]
          })(<Cascader options={mapsOption} onChange={handleMapChange} />)}
        </Form.Item>
        <Form.Item>{getFieldDecorator('quickmatch')(<Checkbox>Quickmatch</Checkbox>)}</Form.Item>
        <Button type="primary" htmlType="submit" className={styles.saveButton} loading={loading}>
          Create
        </Button>
      </Form>
      {
        <Modal
          visible={visible}
          title="Add a user"
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <UserTeamForm
            usersTeams={usersTeams}
            addUserTeam={addUserTeam}
            closeModal={() => setVisible(false)}
          />
        </Modal>
      }
    </>
  )
}

const BattleForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    Form.create<IProps>({ name: 'battle_form' })(BattleFormComponent)
  )
)

export default BattleForm
