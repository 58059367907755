import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import socketClient from 'socket.io-client'
import { IRootState } from '..'
import settings from '../../settings'
import { autoAuth } from '../auth'
import { socketConnected, socketConnecting, socketConnectionError } from './actions'

export let socket: SocketIOClient.Socket | null = null

export const socketConnect = () => (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => {
  if (socket !== null) socket.disconnect()

  // Initiate Socket connection
  socket = socketClient(settings.socketServer.url)

  dispatch(socketConnecting(socket))

  // Connection success
  socket.on('connect', () => {
    // /!\ The order of dispatch events counts, in order to avoid redirection by Dashboard on mount
    try {
      dispatch(autoAuth())
      dispatch(socketConnected())
    } catch (e) {
      console.log(e)
    }
  })

  // Connection error
  for (const eventName of [
    'connect_error',
    'connect_timeout',
    'disconnect',
    'reconnect_error',
    'reconnect_failed'
  ]) {
    socket.on(eventName, () => dispatch(socketConnectionError()))
  }

  // Connection error
  for (const eventName of ['error', 'exception']) {
    socket.on(eventName, (e: any) => console.log(e))
  }

  // Reconnection
  socket.on('reconnecting', () => dispatch(socketConnecting()))
}
