import { Teams } from '@cityinvaders/dtos'
import { Button, Form, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import styles from './UserTeamForm.module.scss'

interface IOwnProps {
  usersTeams: Array<{ userId: string; team: Teams }>
  addUserTeam: (userTeam: { userId: string; team: Teams }) => void
  closeModal: () => void
}

const mapStateToProps = (state: IRootState) => ({
  users: state.userState.users
})

type IProps = ReturnType<typeof mapStateToProps> & FormComponentProps & IOwnProps

const UserTeamFormComponent: FC<IProps> = ({
  form,
  usersTeams,
  users,
  addUserTeam,
  closeModal
}) => {
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    validateFieldsAndScroll(async (errors, values: { userId: string; team: Teams }) => {
      if (errors) {
        return
      }

      addUserTeam(values)
      resetFields()
      closeModal()
    })
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Form.Item label="User">
        {getFieldDecorator('userId', {
          rules: [{ required: true }]
        })(
          <Select>
            {users
              .filter(user => !usersTeams.map(ut => ut.userId).includes(user.id))
              .map(user => (
                <Select.Option key={user.id} value={user.id}>
                  {user.username}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Team">
        {getFieldDecorator('team', {
          rules: [{ required: true }]
        })(
          <Select>
            {Object.keys(Teams).map((team: any) => (
              <Select.Option key={team} value={Teams[team]}>
                {Teams[team]}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Button type="primary" htmlType="submit" className={styles.saveButton}>
        Add
      </Button>
    </Form>
  )
}

const UserTeamForm = Form.create<IProps>({ name: 'user_team_form' })(UserTeamFormComponent)

export default connect(mapStateToProps)(UserTeamForm)
