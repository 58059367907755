import {
  BattleDTO,
  BattleSearchFilterDTO,
  CreateBattleDTO,
  PaginationResponse
} from '@cityinvaders/dtos'
import { AnyAction, Dispatch } from 'redux'
import { socketEmit, socketEmitWithoutAnswer } from '../utils/api'
import { addBattle, removeBattles, setBattles, setLoading } from './actions'

export const fetchBattles = (params: BattleSearchFilterDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const response = await socketEmit<PaginationResponse<BattleDTO>>('Battles.getAll', params)
    dispatch(setBattles(response))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const createBattle = (params: CreateBattleDTO) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoading(true))
  try {
    const response = await socketEmit<BattleDTO>('Battles.create', params)
    dispatch(addBattle(response))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const deleteBattle = (params: string[]) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoading(true))
  try {
    await socketEmitWithoutAnswer('Battles.deleteByIds', params)
    dispatch(removeBattles(params))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}
