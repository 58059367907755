import AntLayout from 'antd/lib/layout'
import React, { FC, useState } from 'react'
import logo from '../../assets/images/logo.png'
import Header from './Header/Header'
import styles from './Layout.module.scss'
import NavBar from './NavBar/NavBar'

const Layout: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <AntLayout className={styles.layout}>
      <AntLayout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div className={styles.logo}>
          <img alt="logo" title="logo" src={logo} />
        </div>
        <NavBar />
      </AntLayout.Sider>
      <AntLayout>
        <Header />
        {children}
      </AntLayout>
    </AntLayout>
  )
}

export default Layout
