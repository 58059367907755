import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ServerConnection from './components/ServerConnection/ServerConnection'
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage'
import BattleCreatePage from './pages/BattleCreatePage/BattleCreatePage'
import BattlesPage from './pages/BattlesPage/BattlesPage'
import HomePage from './pages/HomePage/HomePage'
import ItemTemplatesPage from './pages/ItemTemplatesPage/ItemTemplatesPage'
import LoginPage from './pages/LoginPage/LoginPage'
import UserPage from './pages/UserPage/UserPage'
import UsersPage from './pages/UsersPage/UsersPage'
import store from './redux'
import './styles/global.scss'
import Layout from './templates/Layout/Layout'
import PrivateRoute from './templates/PrivateRoute'

const App = () => (
  <Provider store={store}>
    <ServerConnection>
      <Router>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <PrivateRoute path="/">
            <Layout>
              <Route path="/" exact component={HomePage} />
              <Route path="/users" exact component={UsersPage} />
              <Route path="/users/:id" exact component={UserPage} />
              <Route path="/itemTemplates" exact component={ItemTemplatesPage} />
              <Route path="/battles" exact component={BattlesPage} />
              <Route path="/battles/create" exact component={BattleCreatePage} />
              <Route path="/analytics" exact component={AnalyticsPage} />
            </Layout>
          </PrivateRoute>
        </Switch>
      </Router>
    </ServerConnection>
  </Provider>
)

export default App
