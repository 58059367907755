import { BattleDTO, BattleSearchFilterDTO } from '@cityinvaders/dtos'
import Modal from 'antd/lib/modal'
import { PaginationConfig } from 'antd/lib/pagination'
import Table, { ColumnProps } from 'antd/lib/table'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { deleteBattle, fetchBattles } from '../../redux/battle'
import styles from './BattlesTable.module.scss'

const PAGE_SIZE = 30

const mapStateToProps = (state: IRootState) => ({
  battles: state.battleState.battles,
  total: state.battleState.total,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchBattles: (params: BattleSearchFilterDTO) => dispatch(fetchBattles(params)),
  deleteBattle: (params: string[]) => dispatch(deleteBattle(params))
})

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const BattlesTable: FC<IProps> = props => {
  const columns: Array<ColumnProps<BattleDTO>> = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Map',
      dataIndex: 'map.name'
    },
    {
      title: 'Goal',
      dataIndex: 'goal.name'
    },
    {
      title: 'Quickmatch',
      dataIndex: 'quickmatch',
      render: text => (text ? 'Yes' : 'No')
    },
    {
      title: 'Analytics sent',
      dataIndex: 'analyticsSent',
      render: text => (text ? 'Yes' : 'No')
    },
    {
      title: 'Bug reported',
      dataIndex: 'bugReported',
      render: text => (text ? 'Yes' : 'No')
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div className={styles.action} onClick={() => handleDelete(record.id)}>
          Supprimer
        </div>
      )
    }
  ]

  const { fetchBattles: fetchBattlesStore } = props
  useEffect(() => {
    fetchBattlesStore({ take: PAGE_SIZE })
  }, [fetchBattlesStore])

  const handleTableChange = (pagination: PaginationConfig) => {
    fetchBattlesStore({
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE
    })
  }

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Do you really want to delete this battle ?',
      onOk: () => props.deleteBattle([id])
    })
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.battles}
        rowKey="id"
        pagination={{ total: props.total, defaultPageSize: PAGE_SIZE }}
        onChange={handleTableChange}
        loading={props.loading}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BattlesTable)
