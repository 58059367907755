import { ItemTemplateDTO } from '@cityinvaders/dtos'

export enum ActionsTypes {
  SET_ITEM_TEMPLATES = 'SET_ITEM_TEMPLATES',
  ADD_ITEM_TEMPLATE = 'ADD_ITEM_TEMPLATE',
  SET_ITEM_TEMPLATE = 'SET_ITEM_TEMPLATE',
  SET_ITEM_TEMPLATE_LOADING = 'SET_ITEM_TEMPLATE_LOADING',
  REMOVE_ITEM_TEMPLATE = 'REMOVE_ITEM_TEMPLATE'
}

export interface IItemTemplateState {
  itemTemplates: ItemTemplateDTO[]
  total: number
  loading: boolean
}
