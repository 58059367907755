import { AllianceDTO, AllianceLightDTO } from '@cityinvaders/dtos'

export enum ActionsTypes {
  SET_ALLIANCES = 'SET_ALLIANCES',
  SET_ALLIANCE_LOADING = 'SET_ALLIANCE_LOADING'
}

export interface IAllianceState {
  alliances: Array<AllianceDTO | AllianceLightDTO>
  total: number
  loading: boolean
}
