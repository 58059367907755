import { Button, Icon } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import BattlesTable from '../../components/BattlesTable/BattlesTable'
import styles from './BattlesPage.module.scss'

const BattlesPage = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <h1>Battles</h1>
      <Link to="/battles/create">
        <Button type="primary">
          <Icon type="plus" />
        </Button>
      </Link>
    </div>
    <BattlesTable />
  </div>
)

export default BattlesPage
