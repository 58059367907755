import React from 'react'
import styles from './HomePage.module.scss'

const HomePage = () => (
  <div className={styles.container}>
    <h1>Homepage</h1>
  </div>
)

export default HomePage
