import {
  CharacterDTO,
  CharacterListDTO,
  CreateCharacterDTO,
  DeleteCharacterDTO,
  DeleteUsersDTO,
  GetCharactersByUserIdDTO,
  GetPlaceByUserDTO,
  GetUserDTO,
  GetUserItemsDTO,
  ItemListDTO,
  PaginationResponse,
  PlaceListDTO,
  UpdateCharacterDTO,
  UpdateUserDTO,
  UserPrivateDTO,
  UsersSearchParamsDTO
} from '@cityinvaders/dtos'
import { AnyAction, Dispatch } from 'redux'
import { socketEmit, socketEmitWithoutAnswer } from '../utils/api'
import {
  addUser,
  addUserCharacter,
  removeCharacter,
  removeUsers,
  setLoading,
  setUser,
  setUserCharacter,
  setUserCharacters,
  setUserItems,
  setUserPlaces,
  setUsers
} from './actions'

export const fetchUsers = (params: UsersSearchParamsDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const users = await socketEmit<PaginationResponse<UserPrivateDTO>>('Users.search', params)
    dispatch(setUsers(users))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const fetchUser = (params: GetUserDTO) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoading(true))
  try {
    const user = await socketEmit<UserPrivateDTO>('Users.get', params)
    dispatch(addUser(user))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const updateUser = (params: UpdateUserDTO) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  try {
    const user = await socketEmit<UserPrivateDTO>('Users.edit', params)
    dispatch(setUser(user))
  } catch (e) {
    throw e
  }
  dispatch(setLoading(false))
}

export const deleteUsers = (params: DeleteUsersDTO) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoading(true))
  try {
    await socketEmitWithoutAnswer('Users.disableByIds', params)
    dispatch(removeUsers(params.ids))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const fetchUserCharacters = (params: GetCharactersByUserIdDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const userCharacters = await socketEmit<CharacterListDTO>('Characters.getByUserId', params)
    dispatch(setUserCharacters(userCharacters.characters))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const fetchUserItems = (params: GetUserItemsDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const userItems = await socketEmit<ItemListDTO>('Items.getAll', params)
    dispatch(setUserItems(userItems.items))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const fetchUserPlaces = (params: GetPlaceByUserDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const userPlaces = await socketEmit<PlaceListDTO>('Places.getConquered', params)
    dispatch(setUserPlaces(userPlaces.places))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const createUserCharacter = (params: CreateCharacterDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const character = await socketEmit<CharacterDTO>('Characters.create', params)
    dispatch(addUserCharacter(character))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const deleteUserCharacter = (params: DeleteCharacterDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    await socketEmitWithoutAnswer('Characters.delete', params)
    dispatch(removeCharacter(params.id))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const updateUserCharacter = (params: UpdateCharacterDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const userCharacter = await socketEmit<CharacterDTO>('Characters.edit', params)
    dispatch(setUserCharacter(userCharacter))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}
