import { createReducer } from '../utils'
import {
  addUser,
  addUserCharacter,
  removeCharacter,
  removeUsers,
  setLoading,
  setUser,
  setUserCharacter,
  setUserCharacters,
  setUserItems,
  setUserPlaces,
  setUsers
} from './actions'
import { ActionsTypes, IUserState } from './model'

const initialState: IUserState = {
  users: [],
  userCharacters: [],
  userItems: [],
  userPlaces: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_USERS]: (
    state: IUserState,
    { users }: ReturnType<typeof setUsers>
  ): IUserState => ({
    ...state,
    users: users.data,
    total: users.total
  }),
  [ActionsTypes.SET_USER]: (
    state: IUserState,
    { user }: ReturnType<typeof setUser>
  ): IUserState => ({
    ...state,
    users: state.users.map(u => (u.id === user.id ? user : u))
  }),
  [ActionsTypes.SET_USER_CHARACTERS]: (
    state: IUserState,
    { userCharacters }: ReturnType<typeof setUserCharacters>
  ): IUserState => ({
    ...state,
    userCharacters
  }),
  [ActionsTypes.SET_USER_CHARACTER]: (
    state: IUserState,
    { userCharacter }: ReturnType<typeof setUserCharacter>
  ): IUserState => ({
    ...state,
    userCharacters: state.userCharacters.map(uc =>
      uc.id === userCharacter.id ? userCharacter : uc
    )
  }),
  [ActionsTypes.SET_USER_ITEMS]: (
    state: IUserState,
    { userItems }: ReturnType<typeof setUserItems>
  ): IUserState => ({
    ...state,
    userItems
  }),
  [ActionsTypes.SET_USER_PLACES]: (
    state: IUserState,
    { userPlaces }: ReturnType<typeof setUserPlaces>
  ): IUserState => ({
    ...state,
    userPlaces
  }),
  [ActionsTypes.SET_USER_LOADING]: (
    state: IUserState,
    { loading }: ReturnType<typeof setLoading>
  ): IUserState => ({
    ...state,
    loading
  }),
  [ActionsTypes.ADD_USER]: (
    state: IUserState,
    { user }: ReturnType<typeof addUser>
  ): IUserState => ({
    ...state,
    users: [...state.users.filter(u => u.id !== user.id), user]
  }),
  [ActionsTypes.ADD_USER_CHARACTER]: (
    state: IUserState,
    { character }: ReturnType<typeof addUserCharacter>
  ): IUserState => ({
    ...state,
    userCharacters: [...state.userCharacters, character]
  }),
  [ActionsTypes.REMOVE_USERS]: (
    state: IUserState,
    { ids }: ReturnType<typeof removeUsers>
  ): IUserState => ({
    ...state,
    users: state.users.filter(u => !ids.includes(u.id)),
    total: state.total - ids.length
  }),
  [ActionsTypes.REMOVE_CHARACTER]: (
    state: IUserState,
    { id }: ReturnType<typeof removeCharacter>
  ): IUserState => ({
    ...state,
    userCharacters: state.userCharacters.filter(uc => uc.id !== id)
  })
}

export default createReducer(initialState, actionHandlers)
