import {
  CharacterDTO,
  PaginationResponse,
  PlaceDTO,
  ServerItemDTO,
  UserPrivateDTO
} from '@cityinvaders/dtos'
import { ActionsTypes } from './model'

export const setUsers = (users: PaginationResponse<UserPrivateDTO>) => ({
  type: ActionsTypes.SET_USERS,
  users
})

export const setUser = (user: UserPrivateDTO) => ({ type: ActionsTypes.SET_USER, user })

export const setUserCharacters = (userCharacters: CharacterDTO[]) => ({
  type: ActionsTypes.SET_USER_CHARACTERS,
  userCharacters
})

export const setUserCharacter = (userCharacter: CharacterDTO) => ({
  type: ActionsTypes.SET_USER_CHARACTER,
  userCharacter
})

export const setUserItems = (userItems: ServerItemDTO[]) => ({
  type: ActionsTypes.SET_USER_ITEMS,
  userItems
})

export const setUserPlaces = (userPlaces: PlaceDTO[]) => ({
  type: ActionsTypes.SET_USER_PLACES,
  userPlaces
})

export const setLoading = (loading: boolean) => ({ type: ActionsTypes.SET_USER_LOADING, loading })

export const addUser = (user: UserPrivateDTO) => ({ type: ActionsTypes.ADD_USER, user })

export const addUserCharacter = (character: CharacterDTO) => ({
  type: ActionsTypes.ADD_USER_CHARACTER,
  character
})

export const removeUsers = (ids: string[]) => ({ type: ActionsTypes.REMOVE_USERS, ids })

export const removeCharacter = (id: string) => ({ type: ActionsTypes.REMOVE_CHARACTER, id })
