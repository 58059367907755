import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import allianceState, { IAllianceState } from './alliance'
import authState, { IAuthState } from './auth'
import battleState, { IBattleState } from './battle'
import itemTemplateState, { IItemTemplateState } from './itemTemplate'
import mapState, { IMapState } from './map'
import socketState, { ISocketState } from './socket'
import userState, { IUserState } from './user'

const reducers = () =>
  combineReducers({
    authState,
    socketState,
    userState,
    allianceState,
    itemTemplateState,
    battleState,
    mapState
  })

export interface IRootState {
  authState: IAuthState
  socketState: ISocketState
  userState: IUserState
  allianceState: IAllianceState
  itemTemplateState: IItemTemplateState
  battleState: IBattleState
  mapState: IMapState
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(reducers(), composeEnhancers(applyMiddleware(thunk)))
