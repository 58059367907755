import { CharacterDTO, PlaceDTO, ServerItemDTO, UserPrivateDTO } from '@cityinvaders/dtos'

export enum ActionsTypes {
  SET_USERS = 'SET_USERS',
  SET_USER = 'SET_USER',
  SET_USER_CHARACTERS = 'SET_USER_CHARACTERS',
  SET_USER_CHARACTER = 'SET_USER_CHARACTER',
  SET_USER_ITEMS = 'SET_USER_ITEMS',
  SET_USER_PLACES = 'SET_USER_PLACES',
  SET_USER_LOADING = 'SET_USER_LOADING',
  ADD_USER = 'ADD_USER',
  ADD_USER_CHARACTER = 'ADD_USER_CHARACTER',
  REMOVE_USERS = 'REMOVE_USERS',
  REMOVE_CHARACTER = 'REMOVE_CHARACTER'
}

export interface IUserState {
  users: UserPrivateDTO[]
  userCharacters: CharacterDTO[]
  userItems: ServerItemDTO[]
  userPlaces: PlaceDTO[]
  total: number
  loading: boolean
}
