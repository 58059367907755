import {
  ArmorPlacements,
  ArmorTypes,
  CreateItemTemplateDTO,
  ItemTypes,
  Rarity,
  WeaponTypes
} from '@cityinvaders/dtos'
import { Button, Select } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React, { FC, FormEvent } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { createItemTemplate } from '../../redux/itemTemplate'
import styles from './ItemTemplateForm.module.scss'

interface IOwnProps {
  closeModal?: () => void
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.itemTemplateState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createItemTemplate: (params: CreateItemTemplateDTO) => dispatch(createItemTemplate(params))
})

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FormComponentProps &
  IOwnProps

const ItemTemplateFormComponent: FC<IProps> = ({
  closeModal,
  loading,
  form,
  createItemTemplate: createItemTemplateStore
}) => {
  const { getFieldDecorator, validateFieldsAndScroll, resetFields, getFieldValue } = form

  const addItemTemplate = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    validateFieldsAndScroll(async (errors, fieldsValue: CreateItemTemplateDTO) => {
      if (errors) {
        return
      }

      try {
        await createItemTemplateStore(fieldsValue)
        resetFields()
        message.success('The item template was successfully added.')

        if (closeModal) {
          closeModal()
        }
      } catch (e) {
        console.log(e)
        message.error('An error occured when trying to create the item template.')
      }
    })
  }

  return (
    <Form onSubmit={addItemTemplate} className={styles.form}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          rules: [{ required: true }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Prefab name">
        {getFieldDecorator('prefabName', {
          rules: [{ required: true }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Item type">
        {getFieldDecorator('itemType', {
          rules: [{ required: true }]
        })(
          <Select>
            {Object.keys(ItemTypes).map((itemType: any) => (
              <Select.Option key={itemType} value={ItemTypes[itemType]}>
                {ItemTypes[itemType]}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      {getFieldValue('itemType') === ItemTypes.Weapon && (
        <Form.Item label="Weapon type">
          {getFieldDecorator('weaponType', {
            rules: [{ required: true }]
          })(
            <Select>
              {Object.keys(WeaponTypes).map((weaponType: any) => (
                <Select.Option key={weaponType} value={WeaponTypes[weaponType]}>
                  {WeaponTypes[weaponType]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      )}
      {getFieldValue('itemType') === ItemTypes.Armor && (
        <>
          <Form.Item label="Armor type">
            {getFieldDecorator('armorType', {
              rules: [{ required: true }]
            })(
              <Select>
                {Object.keys(ArmorTypes).map((armorType: any) => (
                  <Select.Option key={armorType} value={ArmorTypes[armorType]}>
                    {ArmorTypes[armorType]}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Armor placement">
            {getFieldDecorator('armorPlacement', {
              rules: [{ required: true }]
            })(
              <Select>
                {Object.keys(ArmorPlacements).map((armorPlacement: any) => (
                  <Select.Option key={armorPlacement} value={ArmorPlacements[armorPlacement]}>
                    {ArmorPlacements[armorPlacement]}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </>
      )}
      <Form.Item label="Rarity">
        {getFieldDecorator('rarity', {
          rules: [{ required: true }]
        })(
          <Select>
            {Object.keys(Rarity).map((rarity: any) => (
              <Select.Option key={rarity} value={Rarity[rarity]}>
                {Rarity[rarity]}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Button type="primary" htmlType="submit" className={styles.saveButton} loading={loading}>
        Create
      </Button>
    </Form>
  )
}

const ItemTemplateForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create<IProps>({ name: 'item_template_form' })(ItemTemplateFormComponent)
)

export default ItemTemplateForm
