import { createReducer } from '../utils'
import { setLoading, setSessionId } from './actions'
import { ActionsTypes, IAuthState } from './model'

const initialState: IAuthState = {
  sessionId: localStorage.getItem('sessionId'),
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_SESSION_ID]: (
    state: IAuthState,
    { sessionId }: ReturnType<typeof setSessionId>
  ): IAuthState => ({
    ...state,
    sessionId
  }),
  [ActionsTypes.REMOVE_SESSION_ID]: (state: IAuthState): IAuthState => ({
    ...state,
    sessionId: null
  }),
  [ActionsTypes.SET_AUTH_LOADING]: (
    state: IAuthState,
    { loading }: ReturnType<typeof setLoading>
  ): IAuthState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
