import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { login } from '../../redux/auth'
import styles from './LoginForm.module.scss'

const mapStateToProps = (state: IRootState) => ({
  loading: state.authState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  login: (email: string, password: string) => dispatch(login(email, password))
})

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps

const LoginForm: FC<IProps> = props => {
  const [emailValue, setEmailValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()
    try {
      await props.login(emailValue, passwordValue)
      props.history.push('/')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Form onSubmit={handleSubmit} className={`login-form ${styles.form}`}>
      <Form.Item>
        <Input
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          name="email"
          type="text"
          value={emailValue}
          onChange={e => setEmailValue(e.currentTarget.value)}
        />
      </Form.Item>
      <Form.Item>
        <Input
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Password"
          name="passord"
          type="password"
          value={passwordValue}
          onChange={e => setPasswordValue(e.currentTarget.value)}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit" className={styles.submit} loading={props.loading}>
        Login
      </Button>
    </Form>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm))
