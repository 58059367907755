import { ActionsTypes } from './model'

export const setSessionId = (sessionId: string) => ({
  type: ActionsTypes.SET_SESSION_ID,
  sessionId
})

export const removeSessionId = () => ({ type: ActionsTypes.REMOVE_SESSION_ID })

export const setLoading = (loading: boolean) => ({ type: ActionsTypes.SET_AUTH_LOADING, loading })
