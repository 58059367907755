import { Card, Icon } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import BattleForm from '../../components/BattleForm/BattleForm'
import styles from './BattleCreatePage.module.scss'

const BattleCreatePage = () => {
  return (
    <div className={styles.container}>
      <Link to="/battles">
        <Icon type="arrow-left" />
        Back
      </Link>
      <Card className={styles.card}>
        <BattleForm />
      </Card>
    </div>
  )
}

export default BattleCreatePage
