import {
  CreateItemTemplateDTO,
  DeleteItemTemplateDTO,
  ItemTemplateDTO,
  ItemTemplateSearchFilterDTO,
  PaginationResponse,
  UpdateItemTemplateDTO
} from '@cityinvaders/dtos'
import { AnyAction, Dispatch } from 'redux'
import { socketEmit, socketEmitWithoutAnswer } from '../utils/api'
import {
  addItemTemplate,
  removeItemTemplate,
  setItemTemplate,
  setItemTemplates,
  setLoading
} from './actions'

export const fetchItemTemplates = (params: ItemTemplateSearchFilterDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const response = await socketEmit<PaginationResponse<ItemTemplateDTO>>(
      'ItemTemplates.getAll',
      params
    )
    dispatch(setItemTemplates(response))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const createItemTemplate = (params: CreateItemTemplateDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const itemTemplate = await socketEmit<ItemTemplateDTO>('ItemTemplates.create', params)
    dispatch(addItemTemplate(itemTemplate))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}

export const updateItemTemplate = (params: UpdateItemTemplateDTO) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  try {
    const itemTemplate = await socketEmit<ItemTemplateDTO>('ItemTemplates.edit', params)
    dispatch(setItemTemplate(itemTemplate))
  } catch (e) {
    throw e
  }
  dispatch(setLoading(false))
}

export const deleteItemTemplate = (params: DeleteItemTemplateDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    await socketEmitWithoutAnswer('ItemTemplates.delete', params)
    dispatch(removeItemTemplate(params.name))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}
