import { AllianceDTO, AllianceLightDTO, PaginationResponse } from '@cityinvaders/dtos'
import { ActionsTypes } from './model'

export const setAlliances = (alliances: PaginationResponse<AllianceDTO | AllianceLightDTO>) => ({
  type: ActionsTypes.SET_ALLIANCES,
  alliances
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_ALLIANCE_LOADING,
  loading
})
