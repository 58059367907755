import { ItemTemplateDTO, PaginationResponse } from '@cityinvaders/dtos'
import { ActionsTypes } from './model'

export const setItemTemplates = (itemTemplates: PaginationResponse<ItemTemplateDTO>) => ({
  type: ActionsTypes.SET_ITEM_TEMPLATES,
  itemTemplates
})

export const addItemTemplate = (itemTemplate: ItemTemplateDTO) => ({
  type: ActionsTypes.ADD_ITEM_TEMPLATE,
  itemTemplate
})

export const setItemTemplate = (itemTemplate: ItemTemplateDTO) => ({
  type: ActionsTypes.SET_ITEM_TEMPLATE,
  itemTemplate
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_ITEM_TEMPLATE_LOADING,
  loading
})

export const removeItemTemplate = (name: string) => ({
  type: ActionsTypes.REMOVE_ITEM_TEMPLATE,
  name
})
