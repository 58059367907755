import { createReducer } from '../utils'
import { socketConnecting } from './actions'
import { ActionsTypes, ISocketState } from './model'

const initialState: ISocketState = {
  connecting: false,
  connected: false,
  error: null,
  socket: null
}

const actionHandlers = {
  [ActionsTypes.SOCKET_CONNECTING]: (
    state: ISocketState,
    { socket }: ReturnType<typeof socketConnecting>
  ) => ({
    ...state,
    connecting: true,
    socket
  }),
  [ActionsTypes.SOCKET_CONNECTED]: (state: ISocketState) => ({
    ...state,
    connecting: false,
    connected: true,
    error: null
  }),
  [ActionsTypes.SOCKET_CONNECTION_ERROR]: (state: ISocketState) => ({
    ...state,
    connected: false,
    connecting: false,
    error: null
  })
}

export default createReducer(initialState, actionHandlers)
