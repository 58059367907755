import React from 'react'
import styles from './AnalyticsPage.module.scss'

const AnalyticsPage = () => (
  <div className={styles.container}>
    <h1>Analytics</h1>
  </div>
)

export default AnalyticsPage
