import {
  DeleteItemTemplateDTO,
  ItemTemplateDTO,
  ItemTemplateSearchFilterDTO,
  UpdateItemTemplateDTO
} from '@cityinvaders/dtos'
import { Modal } from 'antd'
import Table, { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import {
  deleteItemTemplate,
  fetchItemTemplates,
  updateItemTemplate
} from '../../redux/itemTemplate'
import EditableCell from '../EditableTable/EditableCell/EditableCell'
import { EditableFormRow } from '../EditableTable/EditableFormRow/EditableFormRow'
import styles from './ItemTemplatesTable.module.scss'

const PAGE_SIZE = 30

const mapStateToProps = (state: IRootState) => ({
  itemTemplates: state.itemTemplateState.itemTemplates,
  total: state.itemTemplateState.total,
  loading: state.itemTemplateState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchItemTemplates: (params: ItemTemplateSearchFilterDTO) => dispatch(fetchItemTemplates(params)),
  updateItemTemplate: (params: UpdateItemTemplateDTO) => dispatch(updateItemTemplate(params)),
  deleteItemTemplate: (params: DeleteItemTemplateDTO) => dispatch(deleteItemTemplate(params))
})

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ItemTemplatesTable: FC<IProps> = props => {
  const {
    fetchItemTemplates: fetchItemTemplatesStore,
    updateItemTemplate: updateItemTemplateStore,
    deleteItemTemplate: deleteItemTemplateStore
  } = props

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  }

  useEffect(() => {
    fetchItemTemplatesStore({ take: PAGE_SIZE })
  }, [fetchItemTemplatesStore])

  const handleSave = useCallback(
    (itemTemplate: ItemTemplateDTO) => updateItemTemplateStore(itemTemplate),
    [updateItemTemplateStore]
  )

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<ItemTemplateDTO>
  ) => {
    const searchFilter: ItemTemplateSearchFilterDTO = {
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE
    }

    if (sorter.field) {
      searchFilter.order = { [sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC' }
    }

    fetchItemTemplatesStore(searchFilter)
  }

  const editableColumns = useMemo(() => {
    const handleDelete = (name: string) => {
      Modal.confirm({
        title: 'Do you really want to delete this item template ?',
        onOk: () => deleteItemTemplateStore({ name })
      })
    }
    const columns: Array<ColumnProps<ItemTemplateDTO> & { editable?: boolean }> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true
      },
      {
        title: 'Prefab name',
        dataIndex: 'prefabName',
        sorter: true,
        editable: true
      },
      {
        title: 'Item type',
        dataIndex: 'itemType',
        sorter: true
      },
      {
        title: 'Weapon type',
        dataIndex: 'weaponType',
        sorter: true
      },
      {
        title: 'Armor type',
        dataIndex: 'armorType',
        sorter: true
      },
      {
        title: 'Armor placement',
        dataIndex: 'armorPlacement',
        sorter: true
      },
      {
        title: 'Rarity',
        dataIndex: 'rarity',
        sorter: true
      },
      {
        title: 'Action',
        render: (text, record) => (
          <div className={styles.action} onClick={() => handleDelete(record.name)}>
            Supprimer
          </div>
        )
      }
    ]
    return columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record: ItemTemplateDTO) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave
        })
      }
    })
  }, [handleSave, deleteItemTemplateStore])

  return (
    <Table
      components={components}
      columns={editableColumns}
      dataSource={props.itemTemplates}
      rowKey="name"
      rowClassName={() => styles.editableRow}
      pagination={{ total: props.total, defaultPageSize: PAGE_SIZE }}
      onChange={handleTableChange}
      loading={props.loading}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemTemplatesTable)
