import { GetPlaceByUserDTO } from '@cityinvaders/dtos'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchUserPlaces } from '../../redux/user'

const mapStateToProps = (state: IRootState) => ({
  userPlaces: state.userState.userPlaces,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchUserPlaces: (params: GetPlaceByUserDTO) => dispatch(fetchUserPlaces(params))
})

interface IOwnProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IOwnProps

const UserPlaces: FC<IProps> = ({ id, userPlaces, fetchUserPlaces: fetchUserPlacesStore }) => {
  // Fetch the user places
  useEffect(() => {
    fetchUserPlacesStore({ userId: id })
  }, [id, fetchUserPlacesStore])

  return (
    <>
      <h1>Places</h1>
      {!userPlaces.length && <p>No places</p>}
      {userPlaces.map(userPlace => (
        <p>{userPlace.name}</p>
      ))}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPlaces)
