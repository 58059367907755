import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { IRootState } from '../redux'

const mapStateToProps = (state: IRootState) => ({
  sessionId: state.authState.sessionId
})

type IProps = ReturnType<typeof mapStateToProps> & RouteProps

/**
 * Route that redirect to login page if user is not connected
 */
const PrivateRoute: FC<IProps> = ({ sessionId, ...rest }) => {
  return sessionId ? <Route {...rest} /> : <Redirect to={{ pathname: '/login' }} />
}

export default connect(mapStateToProps)(PrivateRoute)
