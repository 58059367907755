import { GetUserItemsDTO } from '@cityinvaders/dtos'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchUserItems } from '../../redux/user'

const mapStateToProps = (state: IRootState) => ({
  userItems: state.userState.userItems,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchUserItems: (params: GetUserItemsDTO) => dispatch(fetchUserItems(params))
})

interface IOwnProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IOwnProps

const UserItems: FC<IProps> = ({ id, userItems, fetchUserItems: fetchUserItemsStore }) => {
  // Fetch the user items
  useEffect(() => {
    fetchUserItemsStore({ userId: id })
  }, [id, fetchUserItemsStore])

  return (
    <>
      <h1>Items</h1>
      {!userItems.length && <p>No items</p>}
      {userItems.map(userItem => (
        <p key={userItem.id}>{userItem.reference.name}</p>
      ))}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserItems)
