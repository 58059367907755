import { MapDTO } from '@cityinvaders/dtos'
import { ActionsTypes } from './model'

export const setMaps = (maps: MapDTO[]) => ({
  type: ActionsTypes.SET_MAPS,
  maps
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_MAP_LOADING,
  loading
})
