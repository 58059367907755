import { CustomError } from '@cityinvaders/dtos'
import { message } from 'antd'
import { socket } from '../socket'

const timeout = 3000

export const socketEmit = <T = void>(eventName: string, data?: any): Promise<T> => {
  return new Promise((resolve, reject) => {
    if (socket === null) {
      return reject(new Error('Socket not connected'))
    }

    const timeoutId = setTimeout(() => {
      console.error(`Request timed out ${eventName}`)
      message.error(`Request timed out ${eventName}`)
      // reject(new Error('Request timed out'))
    }, timeout)

    socket.emit(eventName, data, (response: any) => {
      clearTimeout(timeoutId)
      if (response.error) {
        const error = new Error(response.error.message)
        error.name = response.error.name
        message.error(error.name)
        return reject(error)
      }
      if (response.$type === 'LoneStoneStudio.CityInvaders.ErrorDTO, CityInvaders.Runtime') {
        const customError = response as CustomError
        message.error(customError.message)
        return reject(customError.message)
      }
      resolve(response)
    })
  })
}

export const socketEmitWithoutAnswer = async (eventName: string, data?: any): Promise<void> => {
  if (socket === null) {
    throw new Error('Socket not connected')
  }

  const timeoutId = setTimeout(() => {
    throw new Error('Request timed out')
  }, timeout)

  await socket.emit(eventName, data)
  clearTimeout(timeoutId)
  // TODO handle errors
}
