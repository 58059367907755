import { WrappedFormUtils } from 'antd/lib/form/Form'
import React from 'react'

export const EditableContext = React.createContext<WrappedFormUtils | null>(null)

export const EditableRow = ({ form, index, ...props }: any) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)
