export enum ActionsTypes {
  SET_SESSION_ID = 'SET_SESSION_ID',
  REMOVE_SESSION_ID = 'REMOVE_SESSION_ID',
  SET_AUTH_LOADING = 'SET_AUTH_LOADING'
}

export interface IAuthState {
  sessionId: string | null
  loading: boolean
}
