import { createReducer } from '../utils'
import {
  addItemTemplate,
  removeItemTemplate,
  setItemTemplate,
  setItemTemplates,
  setLoading
} from './actions'
import { ActionsTypes, IItemTemplateState } from './model'

const initialState: IItemTemplateState = {
  itemTemplates: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_ITEM_TEMPLATES]: (
    state: IItemTemplateState,
    { itemTemplates }: ReturnType<typeof setItemTemplates>
  ): IItemTemplateState => ({
    ...state,
    itemTemplates: itemTemplates.data,
    total: itemTemplates.total
  }),
  [ActionsTypes.ADD_ITEM_TEMPLATE]: (
    state: IItemTemplateState,
    { itemTemplate }: ReturnType<typeof addItemTemplate>
  ): IItemTemplateState => ({
    ...state,
    itemTemplates: [...state.itemTemplates, itemTemplate]
  }),
  [ActionsTypes.SET_ITEM_TEMPLATE]: (
    state: IItemTemplateState,
    { itemTemplate }: ReturnType<typeof setItemTemplate>
  ): IItemTemplateState => ({
    ...state,
    itemTemplates: state.itemTemplates.map(it =>
      it.name === itemTemplate.name ? itemTemplate : it
    )
  }),
  [ActionsTypes.SET_ITEM_TEMPLATE_LOADING]: (
    state: IItemTemplateState,
    { loading }: ReturnType<typeof setLoading>
  ): IItemTemplateState => ({
    ...state,
    loading
  }),
  [ActionsTypes.REMOVE_ITEM_TEMPLATE]: (
    state: IItemTemplateState,
    { name }: ReturnType<typeof removeItemTemplate>
  ): IItemTemplateState => ({
    ...state,
    itemTemplates: state.itemTemplates.filter(it => it.name !== name)
  })
}

export default createReducer(initialState, actionHandlers)
