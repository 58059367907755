import { GetUserDTO } from '@cityinvaders/dtos'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import UserCard from '../../components/UserCard/UserCard'
import UserCharacters from '../../components/UserCharacters/UserCharacters'
import UserItems from '../../components/UserItems/UserItems'
import UserPlaces from '../../components/UserPlaces/UserPlaces'
import { IRootState } from '../../redux'
import { fetchUser, getUser } from '../../redux/user'
import styles from './UserPage.module.scss'

type RouteProps = RouteComponentProps<{ id: string }>

const mapStateToProps = (state: IRootState, ownProps: RouteProps) => ({
  user: getUser(state, ownProps.match.params.id),
  alliances: state.allianceState.alliances,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchUser: (params: GetUserDTO) => dispatch(fetchUser(params))
})

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps

const UserPage: FC<IProps> = ({ match, user, loading, fetchUser: fetchUserStore }) => {
  // Fetch the current user if he is not already in the store
  useEffect(() => {
    if (!user) {
      fetchUserStore({ id: match.params.id })
    }
  }, [user, fetchUserStore, match])

  return (
    <div className={styles.container}>
      <UserCard user={user} loading={loading} isEditable={true} />
      <UserCharacters id={match.params.id} />
      <UserItems id={match.params.id} />
      <UserPlaces id={match.params.id} />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserPage))
