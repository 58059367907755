import { createReducer } from '../utils'
import { setAlliances, setLoading } from './actions'
import { ActionsTypes, IAllianceState } from './model'

const initialState: IAllianceState = {
  alliances: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_ALLIANCES]: (
    state: IAllianceState,
    { alliances }: ReturnType<typeof setAlliances>
  ): IAllianceState => ({
    ...state,
    alliances: alliances.data,
    total: alliances.total
  }),
  [ActionsTypes.SET_ALLIANCE_LOADING]: (
    state: IAllianceState,
    { loading }: ReturnType<typeof setLoading>
  ): IAllianceState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
