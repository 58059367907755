import Button from 'antd/lib/button'
import AntLayout from 'antd/lib/layout'
import Modal from 'antd/lib/modal'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { logout } from '../../../redux/auth'
import styles from './Header.module.scss'

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  logout: () => dispatch(logout())
})

type IProps = ReturnType<typeof mapDispatchToProps>

const Header: FC<IProps> = props => {
  const handleLogout = () => {
    Modal.confirm({
      title: 'Voulez-vous vraiment vous déconnecter?',
      onOk: () => props.logout()
    })
  }

  return (
    <AntLayout.Header className={styles.header}>
      <Button type="danger" onClick={handleLogout}>
        Déconnexion
      </Button>
    </AntLayout.Header>
  )
}

export default connect(null, mapDispatchToProps)(Header)
