import {
  AllianceDTO,
  AllianceLightDTO,
  AlliancesSearchFilterDTO,
  PaginationResponse
} from '@cityinvaders/dtos'
import { AnyAction, Dispatch } from 'redux'
import { socketEmit } from '../utils/api'
import { setAlliances, setLoading } from './actions'

export const fetchAlliances = (params: AlliancesSearchFilterDTO) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const alliances = await socketEmit<PaginationResponse<AllianceDTO | AllianceLightDTO>>(
      'Alliances.search',
      params
    )
    dispatch(setAlliances(alliances))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}
