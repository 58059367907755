import { Spin } from 'antd'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { socketConnect } from '../../redux/socket'
import styles from './ServerConnection.module.scss'

const mapStateToProps = ({ socketState }: IRootState) => ({
  socketConnecting: socketState.connecting,
  socketConnected: socketState.connected,
  socketError: socketState.error
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  socketConnect: () => dispatch(socketConnect())
})

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ServerConnection: FC<IProps> = ({
  socketConnected,
  socketConnecting,
  socketConnect: socketConnectStore,
  socketError,
  children
}) => {
  useEffect(() => {
    socketConnectStore()
  }, [socketConnectStore])

  if (socketConnected) {
    return <>{children}</>
  }

  return (
    <div className={styles.connectionLoader}>
      <Spin size="large" />
      <div>
        {socketConnecting && 'Connection to server...'}
        {!socketConnecting && socketError && 'Connection error.'}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerConnection)
