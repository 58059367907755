export enum ActionsTypes {
  SOCKET_CONNECTING = 'SOCKET_CONNECTING',
  SOCKET_CONNECTED = 'SOCKET_CONNECTED',
  SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR'
}

export interface ISocketState {
  connecting: boolean
  connected: boolean
  error: Error | null
  socket: any | null
}
