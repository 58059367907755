import React from 'react'
import logo from '../../assets/images/logo.png'
import LoginForm from '../../components/LoginForm/LoginForm'
import styles from './LoginPage.module.scss'

const LoginPage = () => (
  <div className={styles.container}>
    <img alt="Logo" title="Logo" src={logo} />
    <LoginForm />
  </div>
)

export default LoginPage
