import { LoginResponseDTO } from '@cityinvaders/dtos'
import { AnyAction, Dispatch } from 'redux'
import {
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
  setUserInLocalStorage
} from '../../utils/localStorageUtils'
import { socketEmit } from '../utils/api'
import { removeSessionId, setLoading, setSessionId } from './actions'

export const login = (email: string, password: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoading(true))
  try {
    const auth = await socketEmit<LoginResponseDTO>('Users.auth', {
      email,
      password,
      playing: false
    })
    const user = auth.user
    if (!user.admin) throw new Error('ACCESS_DENIED')
    dispatch(setSessionId(auth.sessionId))
    setUserInLocalStorage(auth)
  } catch (e) {
    dispatch(setLoading(false))
    throw e
  }
  dispatch(setLoading(false))
}

// Auto auth is not working anymore for the backoffice
export const autoAuth = () => async (dispatch: Dispatch<AnyAction>) => {
  const { sessionId, userId } = getUserFromLocalStorage()
  if (!sessionId || !userId) return

  dispatch(setLoading(true))
  try {
    const auth = await socketEmit<LoginResponseDTO>('Users.authBySession', { sessionId, userId })
    const user = auth.user
    if (!user || !user.admin) throw new Error('ACCESS_DENIED')
    dispatch(setSessionId(auth.sessionId))
    setUserInLocalStorage(auth)
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(removeSessionId())
    removeUserFromLocalStorage()
    throw e
  }
  dispatch(setLoading(false))
}

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(removeSessionId())
  removeUserFromLocalStorage()
}
