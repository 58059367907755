import { BattleDTO, PaginationResponse } from '@cityinvaders/dtos'
import { ActionsTypes } from './model'

export const setBattles = (battles: PaginationResponse<BattleDTO>) => ({
  type: ActionsTypes.SET_BATTLES,
  battles
})

export const addBattle = (battle: BattleDTO) => ({
  type: ActionsTypes.ADD_BATTLE,
  battle
})

export const removeBattles = (ids: string[]) => ({ type: ActionsTypes.REMOVE_BATTLES, ids })

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_BATTLE_LOADING,
  loading
})
