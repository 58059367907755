import Icon from 'antd/lib/icon'
import Menu from 'antd/lib/menu'
import React, { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const NavBar: FC<RouteComponentProps> = ({ location, history }) => (
  <Menu
    theme="dark"
    mode="inline"
    defaultSelectedKeys={[location.pathname]}
    onClick={param => history.push(param.key)}
  >
    <Menu.Item key="/" style={{ marginTop: 0 }}>
      <Icon type="home" />
      <span>Home</span>
    </Menu.Item>
    <Menu.Item key="/users">
      <Icon type="team" />
      <span>Users</span>
    </Menu.Item>
    <Menu.Item key="/itemTemplates">
      <Icon type="tool" />
      <span>Item templates</span>
    </Menu.Item>
    <Menu.Item key="/battles">
      <Icon type="thunderbolt" />
      <span>Battles</span>
    </Menu.Item>
    <Menu.Item key="/analytics">
      <Icon type="thunderbolt" />
      <span>Analytics</span>
    </Menu.Item>
  </Menu>
)

export default withRouter(NavBar)
