import { Input } from 'antd'
import Form from 'antd/lib/form'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { ColumnProps } from 'antd/lib/table'
import React, { FC, useEffect, useRef, useState } from 'react'
import { EditableContext } from '../EditableRow/EditableRow'
import styles from './EditableCell.module.scss'

interface IProps {
  handleSave: (param: any) => void
  editable: boolean
  record: any
}

const EditableCell: FC<ColumnProps<any> & IProps> = ({
  children,
  dataIndex,
  record,
  title,
  handleSave,
  editable,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  let input: any = useRef(null)
  let form: WrappedFormUtils | null

  useEffect(() => {
    if (editing) {
      input.focus()
    }
  }, [editing, input])

  const toggleEdit = () => {
    setEditing(!editing)
  }

  const save = () => {
    if (form) {
      toggleEdit()
      handleSave({ ...record, ...form.getFieldsValue() })
    }
  }

  const renderCell = (formInjected: WrappedFormUtils | null) => {
    form = formInjected
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form &&
          form.getFieldDecorator(dataIndex as string, {
            initialValue: record[dataIndex as string]
          })(<Input ref={node => (input = node)} onPressEnter={save} onBlur={save} />)}
      </Form.Item>
    ) : (
      <div className={styles.editableCellValueWrap} onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return (
    <td {...restProps}>
      {editable ? <EditableContext.Consumer>{renderCell}</EditableContext.Consumer> : children}
    </td>
  )
}

export default EditableCell
