import { Teams } from '@cityinvaders/dtos/dist'
import { Icon } from 'antd'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import { getUser } from '../../../redux/user'
import styles from './UserTeamCard.module.scss'

interface IOwnProps {
  userTeam: { userId: string; team: Teams }
  removeUserTeam: (userId: string) => void
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  user: getUser(state, ownProps.userTeam.userId)
})

type IProps = ReturnType<typeof mapStateToProps> & IOwnProps

const UserTeamCard: FC<IProps> = ({ userTeam, user, removeUserTeam }) => {
  return (
    <div className={styles.card}>
      {user && user.username} - {userTeam.team}
      <Icon
        type="delete"
        className={styles.delete}
        onClick={() => removeUserTeam(userTeam.userId)}
      />
    </div>
  )
}

export default connect(mapStateToProps)(UserTeamCard)
