import { MapListDTO } from '@cityinvaders/dtos'
import { AnyAction, Dispatch } from 'redux'
import { socketEmit } from '../utils/api'
import { setLoading, setMaps } from './actions'

export const fetchMaps = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoading(true))
  try {
    const response = await socketEmit<MapListDTO>('Maps.getAll')
    dispatch(setMaps(response.maps))
  } catch (e) {
    console.log(e)
  }
  dispatch(setLoading(false))
}
