import { createReducer } from '../utils'
import { setLoading, setMaps } from './actions'
import { ActionsTypes, IMapState } from './model'

const initialState: IMapState = {
  maps: [],
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_MAPS]: (state: IMapState, { maps }: ReturnType<typeof setMaps>): IMapState => ({
    ...state,
    maps
  }),
  [ActionsTypes.SET_MAP_LOADING]: (
    state: IMapState,
    { loading }: ReturnType<typeof setLoading>
  ): IMapState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
